import styled from "@emotion/styled"
import React from "react"
import colors from "../../styles/colors"
import { typographyStyles } from "../typography"

const TestDriveLabelElement = styled.h3`
  ${typographyStyles.button}
  font-weight: 500;
  padding: 5px 12px 2px;
  border-radius: 4px;
  background: ${colors.primary};
  color: ${colors.white};
`

export const TestDriveLabel = (
  props: Omit<React.ComponentProps<typeof TestDriveLabelElement>, "children">
) => <TestDriveLabelElement {...props}>Test Drive</TestDriveLabelElement>
